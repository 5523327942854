import Head from 'next/head'
import { useRouter } from 'next/router'
import type { NextPage } from 'next'
import { useEffect } from 'react'

const Home: NextPage = () => {
  const router = useRouter()
  useEffect(() => {
    router.push('/trade')
  }, [])
  return (
    <>
      <Head>
        <title>Messaging</title>
        <meta name="description" content="Simple Financial Messaging App" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon.ico" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon.ico" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon.ico" />
        <link rel="manifest" href="/site.webmanifest"></link>
      </Head>
    </>
  )
}

export default Home
